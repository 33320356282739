
export interface UsersListResponse {
    data: AdminCustomerUser[] | null,
    meta: { total: number, page: number },
    status: boolean
}

export interface UsersListNewResponse {
    // data: AdminCustomerUserNew| null,
    data: { users: AdminCustomerUser[] | null, page: number, limit: number, totalCount: number }
    message: string,
    status: boolean
}
export interface AdminCustomerUser {
    country: any
    _id?: string
    user_id?: string
    created?: any //date
    app_name?: string,
    city: string,
    state: string,
    post_code: string,
    phone_number?: string,
    last_name?: string,
    first_name?: string,
    middle_name?: string,
    wallet_no?: string,
    scheme_code?: string,
    customer_num?: string
    account_id?: string
    sort_code?: string
    account_num?: string
    route_no?: string
    account_type?: string
    email?: string,
    address?: AdminCustomerAddress,
    address_2: string,
    railsbank_cards: AdminCustomerCards[]
    transactions_sent?: string
    transactions_received?: string
    value_sent?: string
    value_received?: string
    key?: string
    platform?: string
    card_num?: string
    swift_id?: string
    IBAN?: string
    iban?: string
    limit_level?: string
    employment_status?: string
    marital_status?: string
    gender?: string
    dateOfBirth?: string
    gift_aid?: string
    gift_aid_date?: string
    updated: any // date
    disabled?: boolean
    referred_by?: string
    banned?: boolean,
    trusted?: boolean,
    version?: string
    issuer_code?: string
    sub_issuer_code?: string
    bvn?: string
    ios_outdated?: boolean
    tax_id?: string
    org_code?: string
    org_br_code?: string
    id_code?: string
    card_settings?: { package: string }
    currency?: string
}
export interface AdminCustomerUserNew {
    users: AdminCustomerUser[] | null,
    page: number,
    limit: number,
    totalCount: number
}

export interface AdminCustomerAddress {
    country?: string;
    city?: string;
    line1?: string;
    line2?: string;
    state?: string;
    post_code?: string;
}

export interface AdminCustomerCards {
    _id: string;
    date_time: Date;
    ledger_id: string;
    card_id: string;
}

export interface SearchData {
    searchValue?: any;
    startDate: any;
    endDate: any;
    countryCode?: any;
    selectedTransactionTypes?: string[];
    selectedAccountTypes?: string[];
    selectedChannelIDs?: string[];
}
export interface AdminRefundAfrica {
    dl_code: string;
    src_country: string;
    src_scheme: string;
    dest_wallet_no: string;
    amount: string;
    dest_person: string;
    src_person: string;
    currency: string;
    reference: string;
    OTP: string;
    otp_sid: string;
    des_card_num: string;
}

export interface AdminPayment {
    channel: string;
    to_country: string;
    from_country: string;
    from_acct: string;
    to_acct: string;
    from_currency: string;
    to_currency: string;
    dl_code: string;
    otp_sid: string;
    OTP: string;
    to_provider_code: string;
    account_name: string;
    description: string;
    from_amount: string;
    to_amount: string;
    type: string;
    from_business?: boolean | undefined
    to_business?: boolean | undefined
    merchant_id?: string
}

export interface AdminLimit {
    country_code?: string;
    transfer_daily_limit?: number;
    transfer_monthly_limit?: number;
    topup_daily_limit?: number;
    topup_monthly_limit?: number;
    bills_daily_limit?: number;
    bills_monthly_limit?: number;
    atm_daily_limit?: number;
    atm_monthly_limit?: number;
    pos_daily_limit?: number;
    pos_monthly_limit?: number;
    total_daily_limit?: number;
    total_monthly_limit?: number;
    level?: string;
    otp_treshold?: number;
}

export interface SearchData {
    searchValue?: any;
    startDate: any;
    endDate: any;
    countryCode?: any;
}

export interface AdminCustomerUserTransaction {
    date?: any; // date object
    channel_id?: string;
    account_type?: string;
    currency_code?: string;
    src_last_name?: string;
    src_first_name?: string;
    src_account?: string;
    src_amount_paid?: string;
    src_scheme_code?: string;
    src_sort_route_no?: string;
    des_last_name?: string;
    des_first_name?: string;
    des_account?: string;
    des_amount_paid?: string;
    des_scheme_code?: string;
    des_sort_route_no?: string;
    des_country_code?: string;
    description?: string;
    exchange_rate?: string;
    gift_aid?: string;
    merchant_id?: string;
    payer_id?: string;
    payment_code?: string;
    process_id?: string;
    product_id?: string;
    ref_no?: string;
    reference_no?: string;
    sent_status?: string;
    service_number?: string;
    trans_code?: string;
    trans_type?: string;
}


export interface AdminCustomerTransaction {
    date?: any; // date object
    channel_id?: string;
    account_type?: string;
    currency_code?: string;
    src_last_name?: string;
    src_first_name?: string;
    src_account?: string;
    src_amount_paid?: string;
    src_scheme_code?: string;
    src_sort_route_no?: string;
    des_last_name?: string;
    des_first_name?: string;
    des_account?: string;
    des_amount_paid?: string;
    des_scheme_code?: string;
    des_sort_route_no?: string;
    des_country_code?: string;
    description?: string;
    exchange_rate?: string;
    gift_aid?: string;
    merchant_id?: string;
    payer_id?: string;
    payment_code?: string;
    process_id?: string;
    product_id?: string;
    ref_no?: string;
    reference_no?: string;
    sent_status?: string;
    service_number?: string;
    trans_code?: string;
    trans_type?: string;
}

export interface MerchantAccount {
    _id: string;
    merchant_id: string;
    account_id: string;
    account_number: string;
    sortCode: string;
    __v: number;
    merchant_info: MerchantInfo[];
    products: Product[];
}

export interface Product {
    _id: string;
    product_id: string;
    account: Account;
    bank: Bank2;
    fees: Fees;
    wallet: string;
    scheme_code: string;
    product_no: string;
    name: string;
    level_code: string;
    type_code: string;
    card_num: string;
    merchant_id: string;
    payment_code: string;
    callback: string;
    amount?: any;
    category_id: string;
    organization_no: string;
    country_code: string;
    hide: boolean;
    pos: boolean;
    web: boolean;
    __v: number;
}

export interface Account {
    accountNumber: string;
    account_id: string;
    currency: string;
    type: string;
    providerExtraInfo: string;
    sortCode: string;
}

export interface Bank2 {
    bank_name: string;
    country_code: string;
    bank_code: string;
    account_no: string;
    sort_code: string;
}

export interface Fees {
    percentage: number;
    flat: number;
    minimum_cap: number;
    maximum_cap: number;
}

export interface MerchantInfo {
    _id: string;
    utility: Utility;
    fees_wallet: string;
    associated_banks: AssociatedBank[];
    associated_wallets: any[];
    associated_accounts: any[];
    education_level: string;
    activate: boolean;
    mailing_list: string[];
    fees_package: string;
    gift_aid: boolean;
    bank: Bank;
    currency: Currency;
    customer_id?: string;
    director: Director;
    account_details: AccountDetails;
    merchant_id: string;
    inc_no: string;
    country_code: string;
    reg_name: string;
    reg_address: string;
    street_name: string;
    town: string;
    state: string;
    zip_code: string;
    ent_type: string;
    nature: string;
    remote: boolean;
    trans_type: string;
    volume: string;
    value: string;
    password: string;
    merchant_default_wallet: string;
    date: Date;
    org_no: string;
    __v: number;
}

export interface Utility {
    utility: boolean;
    type: string;
}

export interface AssociatedBank {
    country_code: string;
    bank_code: string;
    account_no: string;
    bank_name: string;
    sort_code: string;
    route_number: string;
}

export interface Bank {
    country_code: string;
    bank_code: string;
    sort_code: string;
    account_no: string;
    bank_name: string;
}

export interface Currency {
    country: string;
    calling_code: string;
    currency_code: string;
    numeric_code: string;
    iso_code_2: string;
    iso_code_3: string;
    currency_sign: string;
}

export interface Director {
    name: string;
    phone: string;
    email: string;
}

export interface AccountDetails {
    account_id: string;
    account_number: string;
    sortCode: string;
}

export interface AdminCustomerMerchant {
    bank: Bank;
    associated_banks: any[];
    associated_wallets: any[];
    associated_accounts: any[];
    currency: Currency;
    education_level: string;
    director: Director;
    account_details: AccountDetails;
    activate: boolean;
    mailing_list: any[];
    utility: Utility;
    fees_package: string;
    gift_aid: boolean;
    _id: string;
    merchant_id: string;
    inc_no: string;
    country_code: string;
    reg_name: string;
    reg_address: string;
    street_name: string;
    town: string;
    state: string;
    zip_code: string;
    ent_type: string;
    nature: string;
    remote: boolean;
    trans_type: string;
    volume: string;
    value: string;
    password: string;
    merchant_default_wallet: string;
    date: Date;
    org_no: string;
    p_name?: string;
    p_refname?: string;
    actual_value?: string;
    actual_volume?: string;
    fees_wallet?: string;
    fees_account?: string;
    customer_id?: string;
    feature_permissions?: any;
}

export interface AdminFees {
    _id?: string;
    country_code?: string;
    level?: string;
    type?: string;
    fees?: {
        percentage?: string;
        flat?: string;
        minimum_cap?: string | number; // added new fields
        maximum_cap?: string | number; // added new fields
    };
}


export interface AdminCustomerMerchantAccount {
    iban: string;
    last_modified_at: Date;
    ledger_primary_use_types: string[];
    ledger_id: string;
    ledger_holder: LedgerHolder;
    ledger_who_owns_assets: string;
    uk_sort_code: string;
    partner_ref: string;
    holder_id: string;
    partner_id: string;
    ledger_t_and_cs_country_of_jurisdiction: string;
    bic_swift: string;
    ledger_status: string;
    amount: number;
    created_at: Date;
    partner_product: string;
    partner: Partner;
    ledger_iban_status: string;
    asset_type: string;
    uk_account_number: string;
    asset_class: string;
    ledger_type: string;
    ledger_meta: LedgerMeta;
}
export interface Company {
    name: string;
}
export interface LedgerHolder {
    enduser_id: string;
    company: Company;
}

export interface Company2 {
    name: string;
}

export interface Partner {
    partner_id: string;
    company: Company2;
    partner_ref: string;
}

export interface LedgerMeta {
    foo: string;
}
export interface AdminFeeAccount {
    account_id: string;
    wallet_name: string;
    country: string;
    account?: string;
    accountNumber: string;
    sortCode: string;
    currency: string;
    customer_id: string;
    country_code: string;
    type: string;
    date: any; // Date Object
}
export enum FilterType {
    ACCOUNT_TYPE = "account_type",
    CHANNEL_ID = "channel_id",
    TRANSACTION_TYPE = "trans_type"
}