import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SESSION_KEY } from 'src/app/storage-manager/session-storage';
import { CreateOrganisationDto } from '../_dto/create-org.dto';
import { CreateStaffDto, CreateBulkStaffDto } from '../_dto/create-staff.dto';
import { AppLocalStorage } from 'src/app/storage-manager/local-storage';
import { UpdatePasswordDto } from '../_dto/update-password.dto';
import { AdminCustomerTransaction } from '../_interfaces/admin-customer-transaction.interface';
import { CreateBillInfoDto } from '../_dto/create-bill-info.dto';
import { UpdateBillInfoDto } from '../_dto/update-bill-info.dto';
import { CreateBlacklistDto } from '../_dto/create-blacklist.dto';
import { UpdateBlacklistInfoDto } from '../_dto/update-blacklist.dto';
import { MerchantAccountOpeningRequestDto } from '../_dto/send-merchant-acc-open.dto';
import { UserAccountOpeningRequestDto } from '../_dto/send-user-acc-open.dto';
import { TransactionParamsDto } from '../_dto/generate-dl-authorization.dto';
import { OtpDl } from '../_interfaces/otp-dl.interface';
import { ExternalFundTransfer, InternalFundTransfer } from '../_dto/fund-transfer.dto';
import { AdminRefundAfrica } from '../_interfaces/admin-refund.interface';
import { Observable } from 'rxjs';

@Injectable({
    providedIn : 'root'
})
export class AdminService {
    DEFAULT_COUNTRY_CODE = "234";
    DEFAULT_COUNTRY_ISO_2 = "NG";
    DEFAULT_CURRENCY_CODE = "NGN";
    constructor(
        private http : HttpClient,
        private appStorage : AppLocalStorage

    ){}

    generateOtpAndDlCode(payload : TransactionParamsDto){
        return this.http.post<XcelHttpResponse<OtpDl>>(`${environment.ADMIN_AUTH_URL}/user/generate/dl-authorization?toSuperAdmin=true`, payload)
    }

    getCountryDetails = (iso_2: string): Observable<any> => this.http.get(`${environment.API_URL}/administrator/countries/${iso_2}`)

    fetchUtilityTypes(): Observable<any> {
        return this.http.get(`${environment.API_URL}/transactions-service/utility-type`).pipe(retry(3))
    }

    fetchImageUrl(file: any): Observable<any> {
        let form = new FormData();
        form.append('image', file);
        return this.http.post(`${environment.API_URL}/transactions-service/bucket/upload`, form).pipe(retry(3))
    }

    updateUtilityMerchant = (requestBody, id): Observable<any> =>  this.http.put(`${environment.API_URL}/transactions-service/merchant/${id}`, requestBody).pipe(retry(3))

    postFundTransfer(payload : InternalFundTransfer | ExternalFundTransfer){
        return this.http.post(`${environment.API_URL}/psp/admin/payment`, payload)
    }

    postRefundTransfer(payload : AdminRefundAfrica){
        return this.http.post(`${environment.API_URL}/psp/admin/refund`, payload)
    }


    getCountriesAndCurrencyCode(){
        return this.http.get(`${environment.API_URL}/countries`)
    }


//
// ─── ADMIN SETTINGS ────────────────────────────────────────────────────────
//

            fetchAdminUsers(){
                return this.http.get(`${environment.ADMIN_AUTH_URL}/user/all`)
            }

            changePassword(updatePasswordDto : UpdatePasswordDto){
                return this.http.put(`${environment.ADMIN_AUTH_URL}/user/update-password`, updatePasswordDto)
            }

            updateAdminUser(scheme_code, wallet_no, requestBody){
                return this.http.put(`${environment.API_URL}/business-api/updateUser/${scheme_code}/${wallet_no}`, requestBody)
            }
            unblockLockedPin(cardId){
                return this.http.put(`${environment.API_URL}/business-api/cards/${cardId}/pin/reset-unblock`, {})
            }
    

            deleteAdminUser(userId){
                return this.http.delete(`${environment.ADMIN_AUTH_URL}/user/account/${userId}`)
            }

            addCountry = (body) => this.http.post(`${environment.ADMIN_AUTH_URL}/countries`, body)

            updateCountry = (body, countryId) => this.http.put(`${environment.ADMIN_AUTH_URL}/countries/${countryId}`, body)

            deleteCountry = (countryId) => this.http.delete(`${environment.ADMIN_AUTH_URL}/countries/${countryId}`)

            fetchbanks = () => this.http.get(`${environment.API_URL}/banks/${this.getSelectedCountryCodeFromSession()}`)

            addbank = (body) => this.http.post(`${environment.API_URL}/banks`, body)

            updatebank = (body,id, bankCode) => this.http.put(`${environment.API_URL}/banks/${id}/${bankCode}/${this.getSelectedCountryCodeFromSession()}`, body)

            deletebank = (id) => this.http.delete(`${environment.API_URL}/banks/${id}`)

            setupMerchantPaymentDetails = (merchantID,body) => this.http.post(`${environment.API_URL}/business-api/updateUser/${merchantID}`, body)

            getMerchantPaymentDetails=(merchantID)=>this.http.get(`${environment.API_URL}/business-api/merchant/details/${merchantID}`)

            setupMerchantPaymentOptionFees = (merchantID,body) => this.http.patch(`${environment.TRANSACTION_SERVICE_URL}/merchant/payment-options/fees/${merchantID}`, body)
//
// ─── ADMIN EXCHNAGE RATE ────────────────────────────────────────────────────────
//
        
            createExchangeRate(body){
                return this.http.post(`${environment.ABBAS_API_URL}/exchange-rates`, body)
            }

            editExchangeRate(id, body){
                return this.http.put(`${environment.ABBAS_API_URL}/exchange-rates/${id}`, body)
            }

            deleteExchangeRate(id){
                return this.http.delete(`${environment.ABBAS_API_URL}/exchange-rates/${id}`)
            }

            getExchangeRate(id){
                return this.http.get(`${environment.ABBAS_API_URL}/exchange-rates/${id}`)
            }

            getExchangeRateByType(type){
                return this.http.get(`${environment.ABBAS_API_URL}/exchange-rates/type/${type}/${this.getSelectedCurrencyCodeFromSession()}`)

            }

//
// ─── ADMIN REPORT ───────────────────────────────────────────────────
//

                
            getAllAgents(){
                return this.http.get(`${environment.ADMIN_REPORT_URL}/agents`)
            }

            getAllAgentsByISOTwo(){
                return this.http.get(`${environment.ADMIN_REPORT_URL}/agents/by/${this.getSelectedCountryFromSession()}`)

            }

            getAllSubAgentsByISOTwo(id){
                return this.http.get(`${environment.ADMIN_REPORT_URL}/agents/sub/${id}/by/${this.getSelectedCountryFromSession()}`)

            }

            createAgent(createAgentDto : any){
                return this.http.post(`${environment.ADMIN_REPORT_URL}/agents`, createAgentDto)

            }

            checkWalletNoAvailability(walletNo){
                return this.http.get(`${environment.ADMIN_REPORT_URL}/agents/wallet-availability?walletNo=${walletNo}`)
            }

            checkAdminEmailAvailability(email){
                return this.http.get(`${environment.ADMIN_REPORT_URL}/agents/email-availability?email=${email}`)
            }
             // - Suspend Agent Toggle
            adminAgentSuspension(setToSuspend: boolean,  userId : string){
                if(setToSuspend){
                    return this.http.put(`${environment.ADMIN_AUTH_URL}/agents/suspend/${userId}`, {})
                }else{
                    return this.http.put(`${environment.ADMIN_AUTH_URL}/agents/unsuspend/${userId}`, {})
                }
            }





//
// ─── ADMIN ORGANISATION  ───────────────────────────────────────────────────
//
            createOrganisation(createOrgDto : CreateOrganisationDto){
                return this.http.post(`${environment.ADMIN_LOAN_URL}/org`, createOrgDto)
            }

            updateOrganisation(createOrgDto : CreateOrganisationDto, editId){
                return this.http.put(`${environment.ADMIN_LOAN_URL}/org/${editId}`, createOrgDto)
            }

            getAllOrganisation(){
                return this.http.get(`${environment.ADMIN_LOAN_URL}/org/by/${this.getSelectedCountryFromSession()}`)
            }

            getAdminOrganisationById(id){
                return this.http.get(`${environment.ADMIN_LOAN_URL}/org/${id}/by/${this.getSelectedCountryFromSession()}`)
            }

            createOrganisationStaff(createOrgDto : CreateStaffDto, orgId : string){
                return this.http.post(`${environment.ADMIN_LOAN_URL}/staff/org/${orgId}`, createOrgDto)
            }

            createOrganisationBulkStaff(createBulkStaff : CreateBulkStaffDto[]){
                return this.http.post(`${environment.ADMIN_LOAN_URL}/staff/bulk`, createBulkStaff)
            }

            updateOrganisationStaff(params : {createOrgDto : CreateStaffDto, orgId : string, editId}){
                return this.http.put(`${environment.ADMIN_LOAN_URL}/staff/org/${params.orgId}/edit/${params.editId}`, params.createOrgDto)
            }

            getAllStaffForOrganisation(orgId){
                return this.http.get(`${environment.ADMIN_LOAN_URL}/staff/org/${orgId}`)
            }
                

            
            getStaffLoanHistory(walletNo, schemeCode){
                return this.http.get(`${environment.ADMIN_LOAN_URL}/lrp/history/${walletNo}/${schemeCode}`)
            }

            getStaffLoanTransaction(staffId){
                return this.http.get(`${environment.ADMIN_LOAN_URL}/lrp/transactions/${staffId}`)
            }

            getStaffLoanTransactionByOrgId(orgId){
                return this.http.get(`${environment.ADMIN_LOAN_URL}/lrp/transactions/${orgId}?searchBy=org`)
            }
                
                
            
//
// ─── ADMIN KYC ───────────────────────────────────────────────────
//

                
            getAllKyc(){
                return this.http.get(`${environment.API_URL}/kyc/all/all`)
            }

            getKycByDateRange(searchData : SearchData){
                return this.http.get(`${environment.API_URL}/kyc/${searchData.startDate}/${searchData.endDate}`)

            }

            getKycErrorsByDateRange(searchData : SearchData){
                return this.http.get(`${environment.API_URL}/kycerrors/${searchData.startDate}/${searchData.endDate}`)

            }

            getAllFailedTransaction(){
                return this.http.get(`${environment.API_URL}/kycerrors/all/all`)
                
            }


//
// ─── ADMIN LIMITS ───────────────────────────────────────────────────────────────
//

        
            getAllLimits(){
                return this.http.get(`${environment.API_URL}/limits/${this.getSelectedCountryCodeFromSession()}`)
            }

            getCountries(){
                // https://sandbox-api.etranzactglobal.com/countries --- use this for alt details
                return this.http.get(`${environment.ADMIN_AUTH_URL}/countries`).pipe(retry(3))
            }

            
            createNewLimit(data){
                return this.http.post(`${environment.API_URL}/limits/country/create/`, data)
            }

            editLimit(countryCode, level, data){
                
                return this.http.put(`${environment.API_URL}/limits/country/${countryCode}/${level}`, data)
            }

 // toUrlEncoded = obj => Object.keys(obj).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])).join('&');
  //
 // ─── ADMIN MERCHANT FEES ────────────────────────────────────────────────────────
 //

    createCardConfiguration(data){
        return this.http.post(`${environment.API_URL}/card/security`, data)

    }
    updateCardConfiguration(data){   
        return this.http.put(`${environment.API_URL}/card/security`, data)
    }

    fetchCardConfiguration(){
        return this.http.get(`${environment.API_URL}/card/security/${this.getSelectedCountryFromSession()}`)
    }

 
 //
 // ─── ADMIN MERCHANT FEES ────────────────────────────────────────────────────────
 //

            getMerchantFee(){
                return this.http.get(`${environment.API_URL}/fees/country/${this.getSelectedCountryCodeFromSession()}`)

            }
            createMerchantFee(data){
                
                return this.http.post(`${environment.API_URL}/fees`, data)
            }

            updateMerchantFee(data){
                
                return this.http.put(`${environment.API_URL}/fees`, data)
            }
 //
 // ─── ADMIN TRANSFER FEES ────────────────────────────────────────────────────────
 //
        getTransferFee(){
            return this.http.get(`${environment.API_URL}/transfers/fees/${this.getSelectedCountryFromSession()}`)

        }

        createTransferFee(data){
            return this.http.post(`${environment.API_URL}/transfers/fees`, data)
        }

        updateTransferFee(data, id){            
            return this.http.put(`${environment.API_URL}/transfers/fees/${id}`, data)
        }

        deleteTransferFee(id){
            return this.http.delete(`${environment.API_URL}/transfers/fees/${id}`)
        }

 //
 // ─── ADMIN TRANSFER FEES ────────────────────────────────────────────────────────
 //
        getProductFee(merchantId){
            return this.http.get(`${environment.API_URL}/merchant/products/`+merchantId)

        }
        createProductFee(productId, data){
            
            return this.http.put(`${environment.API_URL}/product/fee/${productId}`, data)
        }

        updateProductFee(productId, data){
            
            return this.http.put(`${environment.API_URL}/product/fee/${productId}`, data)
        }

     
 //
 // ─── ADMIN USERS ────────────────────────────────────────────────────────────────
 //


        createUserCard(account_id : string, card_type : string){
            return this.http.post(`${environment.API_URL}/psp/card/create/${account_id}/${card_type}`, {})
        }
        setUserLockByWallentNo(toLock : boolean, walletNo){
            let sub_url = 'unlock'
            if(toLock){
                sub_url = 'lock'
            }
            return this.http.get(`${environment.API_URL}/source/${sub_url}/${walletNo}`).pipe(retry(3))

        }
        setUserLockStatusByWalletNoAndSchemeCode(lock_type : string, walletNo, schemeCode){
            return this.http.get(`${environment.API_URL}/source/${lock_type}/${walletNo}/${schemeCode}`).pipe(retry(3))

        }
        getAllUsers(page, pageSize, search){
            let params = new HttpParams();
            params = params.append('page', page)
            params = params.append('limit', pageSize)
            params = params.append('search', search)
            return this.http.get(`${environment.API_URL}/business-api/reports/users/all/all/${this.getSelectedCountryFromSession()}`, {params}).pipe(retry(3))
        }
        // getAllUsers(){
        //     return this.http.get(`${environment.API_URL}/reports/users/all/all/${this.getSelectedCountryFromSession()}`).pipe(retry(3))
        // }

        getUserMonthly(){
            let date = new Date()
            let monthBeginning = new Date(date.getFullYear(), date.getMonth(), 1)
            let monthEnding = new Date(date.getFullYear(), date.getMonth()+1, 0);
            return this.http.get(`${environment.API_URL}/reports/users/${monthBeginning.toISOString()}/${monthEnding.toISOString()}/${this.getSelectedCountryFromSession()}`).pipe(retry(3))
        }

        

        getAllMerchants(page, pageSize, search){
            let params = new HttpParams();
            params = params.append('page', page)
            params = params.append('limit', pageSize)
            params = params.append('search', search)
            return this.http.get(`${environment.BUSINESS_API_URL}/reports/merchants/all/all/${this.getSelectedCountryFromSession()}`, {params}).pipe(retry(3))

        }
        updateAdminMerchant(merchantId, requestBody){
            return this.http.put(`${environment.API_URL}/business-api/updateUser/${merchantId}`, requestBody)
        }   
        // getAllMerchants(){
        //     return this.http.get(`${environment.API_URL}/reports/merchants/all/all/${this.getSelectedCountryFromSession()}`).pipe(retry(3))
        // }
        createXcelMerchantCustomer(requestBody){
            return this.http.post(`${environment.API_URL}/psp/customer`, requestBody).pipe(retry(3))
        }

        getMerchantById(merchantId){
            return this.http.get(`${environment.API_URL}/merchant/details/${merchantId}`).pipe(retry(3))
        }

        getMerchantAccountDetailsById(merchantId){
            return this.http.get(`${environment.API_URL}/psp/customer/accounts/${merchantId}`)
        }

        updateMerchant(updateData : any){
            return this.http.put(`${environment.API_URL}/merchant/update`, updateData)

        }

        getMerchantsMonthly(){
            let date = new Date()
            let monthBeginning = new Date(date.getFullYear(), date.getMonth(), 1)
            let monthEnding = new Date(date.getFullYear(), date.getMonth()+1, 0);
            return this.http.get(`${environment.API_URL}/reports/merchants/${monthBeginning.toISOString()}/${monthEnding.toISOString()}/${this.getSelectedCountryFromSession()}`).pipe(retry(3))
        }

        getMerchantAccountList(page, pageSize, search){
            let params = new HttpParams();
            params = params.append('page', page)
            params = params.append('limit', pageSize)
            params = params.append('search', search)
            return this.http.get(`${environment.API_URL}/merchant/accounts/country/${this.getSelectedCountryCodeFromSession()}`, {params}).pipe(retry(3))
        }
        generateMerchantKey(payload){
            return this.http.post(`${environment.API_URL}/keygen`, payload)
        }
        createCard(requestBody,ledger_id, type){
            return this.http.post(`${environment.API_URL}/psp/card/business/create/${ledger_id}/${type}`, requestBody )
        }
        getAddressByPostCode(postCode, houseNumber){
            return this.http.get(`https://api.getaddress.io/find/${postCode}/${houseNumber}?api-key=cpH_hsIApUu03RKeBkhH1g16515`)
        }
        // getMerchantAccountList(){
        //     return this.http.get(`${environment.API_URL}/merchant/accounts/country/${this.getSelectedCountryCodeFromSession()}`).pipe(retry(3))

        // }

        synchronizeBalance(accountId){
            return this.http.get(`${environment.API_URL}/statement-sync/sync/${accountId}`).pipe(retry(3))
        }

        getMerchantsFilteredByRegistrationDate(searchData : SearchData){
            return this.http.get(`${environment.API_URL}/reports/merchants/${searchData.startDate}/${searchData.endDate}/${this.getSelectedCountryFromSession()}`).pipe(retry(3))
            
        }

        getUserFilteredByTransactions(searchData : SearchData, flag){
            let thresholdCategory = 'inbound_threshold';
            switch(flag){
                case 'received' :
                    thresholdCategory = 'inbound_threshold';
                break;
                case 'sent':
                        thresholdCategory = 'outbound_threshold';
                break;
                default:
                    thresholdCategory = 'inbound_threshold';
            }
            return this.http.get(`${environment.API_URL}/reports/${thresholdCategory}/${searchData.searchValue}/${searchData.startDate}/${searchData.endDate}/${this.getSelectedCountryFromSession()}`)
        }

        getUserFilteredByRegistrationDate(searchData : SearchData){
            return this.http.get(`${environment.API_URL}/reports/users/${searchData.startDate}/${searchData.endDate}/${this.getSelectedCountryFromSession()}`).pipe(retry(3))
        }

        getUserFilteredByMoneySentByCountry(searchData : SearchData, flag){
            let amountCategory = 'inbound_over_amount';
            switch(flag){
                case 'received' :
                    amountCategory = 'inbound_over_amount';
                break;
                case 'sent':
                    amountCategory = 'outbound_over_amount';
                break;
                default:
                   amountCategory = 'inbound_over_amount';
            }
            return this.http.get(`${environment.API_URL}/reports/${amountCategory}/${searchData.searchValue}/${searchData.countryCode}/${searchData.startDate}/${searchData.endDate}/${this.getSelectedCountryFromSession()}`).pipe(retry(3))
        }

        getTransactionsByDateRange(searchData : SearchData){
            return this.http.get(`${environment.API_URL}/reports/transactions/${searchData.startDate}/${searchData.endDate}/${this.getSelectedCountryFromSession()}`).pipe(retry(3))
        }
        getAllTransactions(page, pageSize, search){
            let params = new HttpParams();
            params = params.append('page', page)
            params = params.append('limit', pageSize)
            params = params.append('search', search)
            return this.http.get(`${environment.API_URL}/reports/transactions/all/all/${this.getSelectedCountryFromSession()}`, {params}).pipe(retry(3))

        }
        getDuplicateTransactions(page,limit, startDate, endDate, accountType){
            let params = new HttpParams();
            params = params.append('page', page)
            params = params.append('limit', limit)
            params = params.append('iso_code', this.getSelectedCountryFromSession())
            if(startDate)
            params = params.append('start_date', startDate)
            if(endDate)
            params = params.append('end_date', endDate)
            if(accountType)
            params = params.append('accountType', accountType)
            return this.http.get(`${environment.API_URL}/transactions-service/transactions/duplicate-logs`, {params})
        }

        deleteDuplicateTransactions(payload){
            payload.iso_code = this.getSelectedCountryFromSession()
            return this.http.post(`${environment.API_URL}/transactions-service/transactions/duplicate-logs`, payload)
        }
        // getAllTransactions(page, pageSize, search){
        //     let params = new HttpParams();
        //     params = params.append('page', page)
        //     params = params.append('limit', pageSize)
        //     params = params.append('search', search)
        //     return this.http.get(`https://api.xcelapp.com/transactions-service/transactions/${this.getSelectedCountryFromSession()}`, {params}).pipe(retry(3))

        // }
        // getAllTransactions(){
        //     return this.http.get(`${environment.API_URL}/reports/transactions/all/all/${this.getSelectedCountryFromSession()}`).pipe(retry(3))

        // }

        getAllUserTransactionsById(walletNo){
            return this.http.get(`${environment.API_URL}/reports/users/${walletNo}/transactions/all/all/${this.getSelectedCountryFromSession()}`)
        }

        getUserTransactionsByIdAndDateRange(walletNo, searchData : SearchData ){
            return this.http.get(`${environment.API_URL}/reports/users/${walletNo}/transactions/${searchData.startDate}/${searchData.endDate}/${this.getSelectedCountryFromSession()}`)
        }

        getUserPendingTransactionFeesByIdAndDateRange(walletNo, searchData : SearchData, page:number, limit:number ){
            if(searchData.startDate && searchData.endDate)
                return this.http.get(`${environment.TRANSACTION_SERVICE_URL}/transactions/charge-pending-fees/?des_account=${walletNo}&start_date=${searchData.startDate}&end_date=${searchData.endDate}&country_code=${this.getSelectedCountryFromSession()}&page=${page}&limit=${limit}`)

            return this.http.get(`${environment.TRANSACTION_SERVICE_URL}/transactions/charge-pending-fees/?des_account=${walletNo}&country_code=${this.getSelectedCountryFromSession()}&page=${page}&limit=${limit}`)

        }

        chargePendingTransactionFees(payload : any){
            return this.http.post(`${environment.TRANSACTION_SERVICE_URL}/transactions/charge-pending-fees`, payload)
        }

        getSelectedCountryFromSession(){
            return (this.appStorage.isStoredInSession(SESSION_KEY.DBOARD_COUNTRY_ISO_TWO)) ? 
                    this.appStorage.getFromStorage(SESSION_KEY.DBOARD_COUNTRY_ISO_TWO) : this.DEFAULT_COUNTRY_ISO_2
        }

        getSelectedCountryCodeFromSession(){
            return (this.appStorage.isStoredInSession(SESSION_KEY.DBOARD_COUNTRY_CODE)) ? 
            this.appStorage.getFromStorage(SESSION_KEY.DBOARD_COUNTRY_CODE) : this.DEFAULT_COUNTRY_CODE
        }

        getSelectedCurrencyCodeFromSession(){
            return (this.appStorage.isStoredInSession(SESSION_KEY.DBOARD_CURRENCY_CODE)) ? 
            this.appStorage.getFromStorage(SESSION_KEY.DBOARD_CURRENCY_CODE) : this.DEFAULT_CURRENCY_CODE
        }

        getLoggedInSchemeCode(){
            return new String(this.getSelectedCountryCodeFromSession()+"004").padStart(6, "0")
        }
        




// ADMIN MANAGE ACCOUNTS

        // generateAccount(data){
        //     return this.http.post(`https://cors-anywhere.herokuapp.com/http://52.13.171.28:28082/cuwalletbridge/queryTransactions`, data)
        // }

        generateAccount(data){
            // return this.http.post(`${environment.API_URL}/system/wallet/create`, data)
            return this.http.post(`${environment.FEE_ACCOUNT_ENDPOINT}`, data)
        }

        generateUKAccount(data){
            return this.http.post(`${environment.API_URL}/psp/account`, data)
        }

        auditAccount(data){
            return this.http.post(`${environment.API_URL}/admin/accounts`, data)

        }

        getAdminFeesAccountByCountry(){
            return this.http.get(`${environment.API_URL}/admin/accounts/fees/${this.getSelectedCountryFromSession()}/all`)
        }

        getAdminFeeGroupsByCountry(type){
            return this.http.get(`${environment.API_URL}/fees/country/${this.getSelectedCountryCodeFromSession()}/${type}`)
        }

        getAdminMerchantEntTypes(){
            return this.http.get(`${environment.API_URL}/merchants/types`)
        }

        getManagedAccounts(){
            return this.http.get(`${environment.API_URL}/admin/accounts`);
        }

        getAccountBalanceUk(accountId){
            return this.http.get(`${environment.API_URL}/psp/account/balance/${accountId}`);
        }

        getAccountBalanceOthers(data){
            return this.http.post(`https://xcel-cors-anywhere-ohau7.ondigitalocean.app/http://52.13.171.28:28082/cuwalletbridge/queryTransactions`, data)
        }

        createGlAccount(payload){
            return this.http.post(`${environment.XAS_URL}/admin/accounts/create-gl-account`, payload)
        }


        createHoldAccount(payload){
            return this.http.post(`${environment.XAS_URL}/admin/accounts/create-hl-account`, payload)
        }


        getSettlementAccounts(countryCode){
            return this.http.get(`${environment.API_URL}/admin/accounts/settlements/${countryCode}`)
            // return this.http.get(`https://xcel-cors-anywhere-ohau7.ondigitalocean.app/${environment.API_URL}/admin/accounts/settlements/${countryCode}`)
        }


        logAdminAccount(payload) {
            return this.http.post(`${environment.XAS_URL}/admin/accounts/admin-account`, payload)
        }

        fetchMerchantProducts(merchantId){
            return this.http.get(`${environment.BUSINESS_API_URL}/merchant/products/${merchantId}`)
        }

        // ADMIN REFUND TRANSACTION

        initiateTransactionRefund(transactionDto : AdminCustomerTransaction, type){
            return this.http.post(`${environment.ADMIN_AUTH_URL}/user/transaction-refund/${type}`, transactionDto)
            // return this.http.post(`http://localhost:3000/user/transaction-refund/${type}`, transactionDto)
        }

        //ADMIN USERS ACTIONS
        // - User Pin Block and Unblock
        adminCustomerUserPinBlockChange(setToLock : boolean, walletNo : string, schemeCode : string){
            if(setToLock){
                return this.http.post(`${environment.API_URL}/ban/${walletNo}/${schemeCode}/pin`, {})
            }else{
                return this.http.delete(`${environment.API_URL}/ban/${walletNo}/${schemeCode}`)
            }
        }
        // - Close Account (UK Account currently)
        adminCustomerUserCloseAcountChange(setToClose: boolean, accountId : string ){
            if(setToClose){
                return this.http.delete(`${environment.API_URL}/psp/account/${accountId}/close`)

            }else{
                return this.http.post(`${environment.API_URL}/psp/account/${accountId}/close`, {})

            }
        }

        // - Destory Card (UK Card currently)
        adminCustomerUserDestroy(cardId : string ){
                return this.http.delete(`${environment.API_URL}/psp/card/${cardId}/close`)  
        }

        // - Activate/Deactivate Card (UK Card currently)
        adminCustomerUserActivationCardChange(setToActivate: boolean, cardId : string ){
            if(setToActivate){
                return this.http.post(`${environment.API_URL}/psp/card/${cardId}/unfreeze`, {})
            }else{
                return this.http.post(`${environment.API_URL}/psp/card/${cardId}/suspend`, {})
            }
        }

        // - User Trust Toggle
        adminCustomerUserTrustChange(setToTrusted: boolean,  walletNo : string, schemeCode : string){
            if(setToTrusted){
                return this.http.post(`${environment.API_URL}/source/wallet/trust/${walletNo}/${schemeCode}`, {})
            }else{
                return this.http.delete(`${environment.API_URL}/source/wallet/trust/${walletNo}/${schemeCode}`)
            }
        }

        // - User International Transfer
        adminCustomerUserInternationTransferOptionChange(walletNo: string, schemeCode : string){
            return this.http.post(`${environment.API_URL}/source/wallet/toogle/international/${walletNo}/${schemeCode}`, {})
        }


        // Admin Bill information

        fetchBillInformationList(){
            return this.http.get(`${environment.API_URL}/payment/${this.getSelectedCountryCodeFromSession()}`)
        }
        createBillInformation(createBillDto : CreateBillInfoDto){
            return this.http.post(`${environment.API_URL}/payment`, createBillDto)
        }

        

        updateBillInformation(paymentId : string, updateBillDto : UpdateBillInfoDto){
            return this.http.put(`${environment.API_URL}/payment/${paymentId}`, updateBillDto)
        }

        deleteBillInformation(paymentId : string){
            return this.http.delete(`${environment.API_URL}/payment/${paymentId}`)
        }

        //Admin biller config

        getBillerCountries = () => this.http.get(`${environment.API_URL}/billing-service/countries`).pipe(retry(3))

        getBillerServices = () => this.http.get(`${environment.API_URL}/billing-service/billers/services?country=${this.getSelectedCountryCodeFromSession()}`).pipe(retry(3))

        getBillerChannels = () => this.http.get(`${environment.API_URL}/billing-service/billers/channels`).pipe(retry(3))

        getBillerChannelServices = (countryCode) => this.http.get(`${environment.API_URL}/billing-service/billers/channel-services?country=${countryCode || this.getSelectedCountryCodeFromSession()}`).pipe(retry(3))

        createBillerService = (requestBody) => this.http.post(`${environment.API_URL}/billing-service/billers/services/create`, requestBody).pipe(retry(3))

        createBillerChannel = (requestBody) => this.http.post(`${environment.API_URL}/billing-service/billers/channels/create`, requestBody).pipe(retry(3))

        createBillerChannelServices = (requestBody) => this.http.post(`${environment.API_URL}/billing-service/billers/channel-services/create`, requestBody).pipe(retry(3))

        

        // Admin Blacklist information

        fetchBlacklistInformationList(){
            return this.http.get(`${environment.ADMIN_AUTH_URL}/blacklist/country/${this.getSelectedCountryCodeFromSession()}`)
        }
        createBlacklistInformation(createBlacklistDto : CreateBlacklistDto){
            return this.http.post(`${environment.ADMIN_AUTH_URL}/blacklist`, createBlacklistDto)
        }

        

        updateBlacklistInformation(id : string, updateBlacklistDto : UpdateBlacklistInfoDto){
            return this.http.put(`${environment.ADMIN_AUTH_URL}/blacklist/${id}`, updateBlacklistDto)
        }

        deleteBlacklistInformation(id : string){
            return this.http.delete(`${environment.ADMIN_AUTH_URL}/blacklist/${id}`)
        }


        // Admin user level set

        setAdminUserLevel(walletNo: string , level : number){
            return this.http.put(`${environment.API_URL}/source/limit/${this.getSelectedCountryCodeFromSession()}/${walletNo}`, {limit_level : level})
        }

        //Merchant Limit level

        setAdminMerchantLevel(requestBody){
            return this.http.put(`${environment.API_URL}/business-api/merchant/account/limit`, requestBody)
        }

        //Merchant category config

        getMerchantCategories = () => {
          let params = new HttpParams()
          params = params.append('country_code', this.getSelectedCountryFromSession())
          return this.http.get(`${environment.API_URL}/transactions-service/ent`, {params})
        }

        createMerchantCategory = (payload) => {
            payload.country_code = this.getSelectedCountryFromSession()
            return this.http.post(`${environment.API_URL}/transactions-service/ent`, payload)
        }

        updateMerchantCategory = (payload, id) => {
            payload.country_code = this.getSelectedCountryFromSession()
            return this.http.put(`${environment.API_URL}/transactions-service/ent/${id}`, payload)
        }


        // Admin Welcome Mail

        sendMerchantWelcomeMail(payload : MerchantAccountOpeningRequestDto){
            return this.http.post(`${environment.ADMIN_AUTH_URL}/notification/merchant/account-opening`, payload)
        }

        sendUserWelcomeMail(payload : UserAccountOpeningRequestDto){
            // return this.http.post(`${environment.ADMIN_AUTH_URL}/notification/customer/account-opening`, payload)
            return this.http.post(`${environment.API_URL}/document/email/registration`, payload)
        }

        fetchAccountBalance(numberId, country){
            if(country == "GB"){
                return this.http.get(`${environment.API_URL}/psp/account/balance/${numberId}`).pipe(map( response => response["data"]["balance"]))
            }
            return this.http.get(`${environment.API_URL}/source/wallet/balance/${numberId}`).pipe(map(response => response["data"]["currentBalance"]))
        }

        fetchAccount(numberId, country): Observable<any>{
            if(country == "GB"){
                return this.http.get(`${environment.API_URL}/psp/account/balance/${numberId}`)
            }
            return this.http.get(`${environment.API_URL}/source/wallet/balance/${numberId}`);
        }

        sendAccountStatement = (payload,wallet) => this.http.post(`${environment.API_URL}/document/pdf/account_statement/${wallet}`, payload)

        fetchAdminActivityLog(){
            return this.http.get(`${environment.ADMIN_AUTH_URL}/activity-log`)
        }

        fetchFailedReg(){
            return this.http.get(`${environment.API_URL}/support-service/report/failed-registrations?country_code=${this.getSelectedCountryCodeFromSession()}`)
        }

        deleteFailedReg(id){
            return this.http.delete(`${environment.API_URL}/support-service/report/failed-registrations/${id}`)
        }

        createFailedReg(payload){
            return this.http.post(`${environment.API_URL}/source/wallet/register`, payload)
        }

        sendKycMail(payload){
            return this.http.post(`${environment.API_URL}/support-service/report/kyc-mail`, payload)
        }

        saveToSource(payload){
            return this.http.post(`${environment.API_URL}/admin/accounts/move-merchant-account`, payload)
        }

        //New Kyc and failed reg endpoints
        getKycRecords(startDate = 'all', endDate = 'all', page = 1, pageSize = 5) {
            return this.http.get(`${environment.API_URL}/user-service/verification/user-data?status=success&country=${this.getSelectedCountryFromSession()}&page=${page}&pageSize=${pageSize}`);
          }
        
          getFailedKycRecords(startDate = 'all', endDate = 'all', page = 1, pageSize = 5) {
            return this.http.get(`${environment.API_URL}/user-service/verification/user-data?status=failed&country=${this.getSelectedCountryFromSession()}&page=${page}&pageSize=${pageSize}`);
          }
        
          getKycByScanReference(scanReference: string) {
            return this.http.get(`${environment.API_URL}/user-service/verification/user-data/${scanReference}`);
          }

          updateNonSettlementAccount(payload: any, id: string): Observable<any> {
            return this.http.put(`${environment.XAS_URL}/admin/accounts/admin-account/${id}`, payload);
          }
    
          deleteNonSettlementAccount(id: string): Observable<any> {
            return this.http.delete(`${environment.XAS_URL}/admin/accounts/admin-account/${id}`);
          }
     
}

export interface SearchData {
    searchValue?:any,
    startDate : any,
    endDate : any,
    countryCode?: any
}

export interface XcelHttpResponse<T>{
  status : string | boolean;
  message : string;
  data : T;
}





