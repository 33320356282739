import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class ExchangeRateService {

    constructor(
        private http: HttpClient,
        private sharedService: SharedService
    ){}

    createExchangeRate(body){
        return this.http.post(`${environment.BUSINESS_API_URL}/api/exchange-rates`, body);
    }

    editExchangeRate(id, body){
        return this.http.put(`${environment.BUSINESS_API_URL}/api/exchange-rates/${id}`, body);
    }

    deleteExchangeRate(id){
        return this.http.delete(`${environment.BUSINESS_API_URL}/api/exchange-rates/${id}`);
    }

    getExchangeRate(id){
        return this.http.get(`${environment.BUSINESS_API_URL}/api/exchange-rates/${id}`);
    }

    getExchangeRateByType(type){
        let params = new HttpParams();
        params = params.append('issuer_code', this.sharedService.getSelectedIssuerCodeFromSession())
        params = params.append('sub_issuer_code', this.sharedService.getSelectedSubIssuerCodeFromSession())
        return this.http.get(`${environment.BUSINESS_API_URL}/api/exchange-rates/type/${type}/${this.sharedService.getSelectedCurrencyCodeFromSession()}`, {params});
    }

}
