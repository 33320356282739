// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://api.xcelapp.com',
  TRANSACTION_SERVICE_URL: 'https://api.xcelapp.com/transactions-service',
  USER_SERVICE_URL: 'https://api.xcelapp.com/user-service',
  ABBAS_API_URL: 'https://api.xcelapp.com/api',
  FEE_ACCOUNT_ENDPOINT: 'https://walletapi.etranzactglobal.com/Wallet/Api/Socket/HoldAccount',
  // SETTLEMENT_ACCOUNT_ENDPOINT: 'http://walletapi.etranzactglobal.com/Wallet/Api/Socket',
  SETTLEMENT_ACCOUNT_ENDPOINT: 'http://wallet-api-8260ea05a2dcc081.elb.us-west-2.amazonaws.com/Wallet/Api/Socket',
  ADMIN_REPORT_URL: 'https://api.xcelapp.com/administrator',
  ADMIN_LOAN_URL: 'https://api.xcelapp.com/loan',
  ADMIN_AUTH_URL: 'https://api.xcelapp.com/administrator',
  SUPPORT_SERVICE_URL: 'https://api.xcelapp.com/support-service',
  XAS_URL: 'https://api.xcelapp.com/xas/v1',
  // XAS_URL: 'https://sandbox-api.xcelapp.com/xas/v1',
  ESA_URL: 'https://esa.etranzactglobal.com/api',
  BUSINESS_API_URL: 'https://api.xcelapp.com/business-api',
  CONFIG_PRESET : "SgKOr2mJp",
  // /
  // X_APP_ID: '640b794e6667f7cb1531a666',
  // X_ISSUER_ID: '640b794d6667f7cb1531a65f',
  // PUBLIC_KEY: 'eTz.test_e87f28c9934f7a01ee1b4d572414c7155f403fe3589806e3e346ff5d0b432326',


  X_APP_ID: '64c053730656499e578826c0',
  X_ISSUER_ID: '64c051b60656499e578826bf',
  PUBLIC_KEY: 'eTz.live_faf2ab8387b08df3d51d85c5e43f11e9d46f4a49cd9943b0f2fcd174bb7830d1'

  // API_URL : 'https://sandbox-api.etranzactglobal.com',
  // TRANSACTION_SERVICE_URL : 'https://sandbox-api.xcelapp.com/transactions-service',
  // USER_SERVICE_URL : 'https://sandbox-api.xcelapp.com/user-service',
  // ABBAS_API_URL : 'https://sandbox-api.etranzactglobal.com/api',
  // ADMIN_LOAN_URL: 'https://sandbox-api.etranzactglobal.com/loan',
  // FEE_ACCOUNT_ENDPOINT : 'https://cors-anywhere.herokuapp.com/https://sandbox-bridge.etranzactglobal.com/Wallet/Api/Socket/HoldAccount',
  // SETTLEMENT_ACCOUNT_ENDPOINT: 'https://xcel-cors-anywhere-ohau7.ondigitalocean.app/https://sandbox-bridge.etranzactglobal.com/Wallet/Api/Socket',
  // ADMIN_REPORT_URL : 'https://sandbox-api.etranzactglobal.com/administrator',
  // ADMIN_AUTH_URL : 'https://sandbox-api.etranzactglobal.com/administrator',
  // SUPPORT_SERVICE_URL: 'https://sandbox-api.etranzactglobal.com/support-service'

  // API_URL : 'https://sandbox-api.xcelapp.com',
  // TRANSACTION_SERVICE_URL : 'https://sandbox-api.xcelapp.com/transactions-service',
  // USER_SERVICE_URL : 'https://sandbox-api.xcelapp.com/user-service',
  // ABBAS_API_URL : 'https://sandbox-api.xcelapp.com/api',
  // ADMIN_LOAN_URL: 'https://sandbox-api.xcelapp.com/loan',
  // FEE_ACCOUNT_ENDPOINT : 'https://cors-anywhere.herokuapp.com/https://sandbox-bridge.xcelapp.com/Wallet/Api/Socket/HoldAccount',
  // SETTLEMENT_ACCOUNT_ENDPOINT: 'https://xcel-cors-anywhere-ohau7.ondigitalocean.app/https://sandbox-bridge.xcelapp.com/Wallet/Api/Socket',
  // ADMIN_REPORT_URL : 'https://sandbox-api.xcelapp.com/administrator',
  // ADMIN_AUTH_URL : 'https://sandbox-api.xcelapp.com/administrator',
  // SUPPORT_SERVICE_URL: 'https://sandbox-api.xcelapp.com/support-service',

  // API_URL : 'https://sandbox-api.xcelapp.com',
  // TRANSACTION_SERVICE_URL : 'https://api.xcelapp.com/transactions-service',
  // TRANSACTION_SERVICE_URL : 'https://sandbox-api.xcelapp.com/transactions-service',
  // BUSINESS_API : "https://sandbox-api.xcelapp.com",
  // USER_SERVICE_URL : 'https://sandbox-api.xcelapp.com/user-service',
  // ABBAS_API_URL : 'https://api.xcelapp.com/api',
  // FEE_ACCOUNT_ENDPOINT : 'https://walletapi.etranzactglobal.com/Wallet/Api/Socket/HoldAccount',
  // SETTLEMENT_ACCOUNT_ENDPOINT: 'https://xcel-cors-anywhere-ohau7.ondigitalocean.app/https://walletapi.etranzactglobal.com/Wallet/Api/Socket',
  // ADMIN_REPORT_URL : 'https://api.xcelapp.com/administrator',
  // ADMIN_LOAN_URL: 'https://api.xcelapp.com/loan',
  // ADMIN_AUTH_URL : 'https://api.xcelapp.com/administrator',
  // SUPPORT_SERVICE_URL: 'https://api.xcelapp.com/support-service'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
